<template>
  <div class="settings-und-kosten">
    <page-title slug="settings" alt="Settings und Kosten">
      Settings und Kosten
    </page-title>
    <v-page>
      <article class="text hyphenate" v-html="settingsUndKosten.einleitung"></article>

      <div class="content-img">
        <responsive-image
          :urls="{
            webp: [
              { size: 640, url: require('@/assets/img/settings/settings-1_640.webp') },
              { size: 960, url: require('@/assets/img/settings/settings-1_960.webp') },
              { size: 1280, url: require('@/assets/img/settings/settings-1_1280.webp') },
              { size: 1920, url: require('@/assets/img/settings/settings-1_1920.webp') },
            ],
            jpg: [
              { size: 640, url: require('@/assets/img/settings/settings-1_640.jpg') },
              { size: 960, url: require('@/assets/img/settings/settings-1_960.jpg') },
              { size: 1280, url: require('@/assets/img/settings/settings-1_1280.jpg') },
              { size: 1920, url: require('@/assets/img/settings/settings-1_1920.jpg') },
            ],
          }"
          sizes="(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw"
          alt="Settings"
        ></responsive-image>
      </div>

      <h2 class="article-title">EINZELTHERAPIE</h2>
      <article class="text hyphenate" v-html="settingsUndKosten.einzeltherapie"></article>

      <h2 class="article-title">GRUPPENTHERAPIE</h2>
      <article class="text hyphenate" v-html="settingsUndKosten.gruppentherapie"></article>

      <div class="kosten">
        <div>
          <responsive-image
            class="kosten-img"
            :urls="{
              webp: [
                { size: 640, url: require('@/assets/img/settings/settings-2_640.webp') },
                { size: 960, url: require('@/assets/img/settings/settings-2_960.webp') },
                { size: 1105, url: require('@/assets/img/settings/settings-2_1105.webp') },
              ],
              jpg: [
                { size: 640, url: require('@/assets/img/settings/settings-2_640.jpg') },
                { size: 960, url: require('@/assets/img/settings/settings-2_960.jpg') },
                { size: 1105, url: require('@/assets/img/settings/settings-2_1105.jpg') },
              ],
            }"
            sizes="(min-width: 1280px) calc((min(1120px, calc(100vw - 128px)) - 96px) / 5 * 2), calc((min(1120px, max(80vw, calc(100vw - 128px))) - 96px) / 5 * 2)"
            media="(min-width: 1080px)"
            alt="Kosten"
          ></responsive-image>
        </div>

        <div>
          <h2 class="article-title">KOSTEN (2023)</h2>
          <article class="text hyphenate" v-html="settingsUndKosten.kosten"></article>
        </div>
      </div>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'SettingsUndKosten',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
  },
  data() {
    return {
      settingsUndKosten: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/settings-und-kosten')
    next(vm => (vm.settingsUndKosten = res.data.data))
  },
}
</script>

<style scoped>
.kosten-img {
  display: none;
}

@media all and (min-width: 1080px) {
  .kosten {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 96px;
    align-items: center;
    margin: 4em auto 0;
    width: min(1120px, 100%);
  }

  .kosten-img {
    display: block;
  }

  .kosten .article-title {
    margin-top: 0;
  }
}
</style>
