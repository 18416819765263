var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-und-kosten"},[_c('page-title',{attrs:{"slug":"settings","alt":"Settings und Kosten"}},[_vm._v(" Settings und Kosten ")]),_c('v-page',[_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.settingsUndKosten.einleitung)}}),_c('div',{staticClass:"content-img"},[_c('responsive-image',{attrs:{"urls":{
          webp: [
            { size: 640, url: require('@/assets/img/settings/settings-1_640.webp') },
            { size: 960, url: require('@/assets/img/settings/settings-1_960.webp') },
            { size: 1280, url: require('@/assets/img/settings/settings-1_1280.webp') },
            { size: 1920, url: require('@/assets/img/settings/settings-1_1920.webp') } ],
          jpg: [
            { size: 640, url: require('@/assets/img/settings/settings-1_640.jpg') },
            { size: 960, url: require('@/assets/img/settings/settings-1_960.jpg') },
            { size: 1280, url: require('@/assets/img/settings/settings-1_1280.jpg') },
            { size: 1920, url: require('@/assets/img/settings/settings-1_1920.jpg') } ],
        },"sizes":"(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw","alt":"Settings"}})],1),_c('h2',{staticClass:"article-title"},[_vm._v("EINZELTHERAPIE")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.settingsUndKosten.einzeltherapie)}}),_c('h2',{staticClass:"article-title"},[_vm._v("GRUPPENTHERAPIE")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.settingsUndKosten.gruppentherapie)}}),_c('div',{staticClass:"kosten"},[_c('div',[_c('responsive-image',{staticClass:"kosten-img",attrs:{"urls":{
            webp: [
              { size: 640, url: require('@/assets/img/settings/settings-2_640.webp') },
              { size: 960, url: require('@/assets/img/settings/settings-2_960.webp') },
              { size: 1105, url: require('@/assets/img/settings/settings-2_1105.webp') } ],
            jpg: [
              { size: 640, url: require('@/assets/img/settings/settings-2_640.jpg') },
              { size: 960, url: require('@/assets/img/settings/settings-2_960.jpg') },
              { size: 1105, url: require('@/assets/img/settings/settings-2_1105.jpg') } ],
          },"sizes":"(min-width: 1280px) calc((min(1120px, calc(100vw - 128px)) - 96px) / 5 * 2), calc((min(1120px, max(80vw, calc(100vw - 128px))) - 96px) / 5 * 2)","media":"(min-width: 1080px)","alt":"Kosten"}})],1),_c('div',[_c('h2',{staticClass:"article-title"},[_vm._v("KOSTEN (2023)")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.settingsUndKosten.kosten)}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }